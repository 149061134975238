<template>
  <div
    class="
      page-container
      w-screen
      h-vh-9/10
      max-vh-9/10
      overflow-y-hidden
      relative
      flex flex-wrap
      justify-center
    "
  >
    <div class="container flex justify-center flex-wrap">
      <div
        class="flex flex-wrap w-screen max-h-9/10-screen overflow-y-hidden"
      >
        <div class="flex flex-wrap w-full flex-col">
          <h1 class="w-full text-center my-5 text-primary text-3xl">
            Reestablecer contraseña
          </h1>
          <div class="flex flex-wrap flex-col justify-center my-16">
            <div class="container">
              <form @submit.prevent="onSubmit">
                <div class="col text-right mb-3">
                  <label
                    for="name"
                    class="
                      absolute
                      left-1
                      -top-6
                      w-20
                      sm:w-44
                      leading-5
                      text-left
                      flex
                      items-end
                      sm:relative
                      sm:left-0
                      sm:top-0
                    "
                    >Contraseña</label
                  >
                  <div class="flex-1 relative">
                    <input
                      v-model="formData.password"
                      type="password"
                      placeholder="Contraseña"
                      class="
                        w-full
                        text-primary
                        px-5
                        text-lg
                        border border-primary-light
                      "
                      :class="[showErrors && $v.formData.password.$invalid ? 'invalid' : '']"
                    />
                    <div
                      class="error"
                      v-if="showErrors && !$v.formData.password.required"
                    >
                      Debes elegir una contraseña de almenos 5 <br />carácteres
                      y al menos un símbolo.
                    </div>
                    <div
                      class="error"
                      v-if="showErrors && !$v.formData.password.minLength"
                    >
                      La contraseña debe tener al menos
                      {{
                        $v.formData.password.$params.minLength.min
                      }}
                      carácteres.
                    </div>
                    <div
                      class="error"
                      v-if="showErrors && !$v.formData.password.hasSymbol"
                    >
                      La contraseña debe tener al menos un símbolo.
                    </div>
                  </div>
                </div>
                <div class="col text-right mb-3">
                  <label
                    for="name"
                    class="
                      absolute
                      left-1
                      -top-6
                      w-20
                      sm:w-44
                      leading-5
                      text-left
                      flex
                      items-end
                      sm:relative
                      sm:left-0
                      sm:top-0
                    "
                    >Repetir Contraseña</label
                  >
                  <div class="flex-1 relative">
                    <input
                      v-model="formData.password_c"
                      type="password"
                      placeholder="Repetir contraseña"
                      class="
                        w-full
                        text-primary
                        px-5
                        text-lg
                        border border-primary-light
                      "
                      :class="[
                        showErrors && $v.formData.password_c.$invalid ? 'invalid' : '',
                      ]"
                    />
                    <div
                      class="error"
                      v-if="showErrors && !$v.formData.password_c.required"
                    >
                      Requerido.
                    </div>
                    <div
                      class="error"
                      v-if="
                        showErrors && !$v.formData.password_c.sameAsPassword
                      "
                    >
                      La contraseña debe ser igual a la anterior.
                    </div>
                  </div>
                </div>
                <div class="w-full flex justify-center">
                  <button
                    class="
                      bg-primary
                      rounded-xl
                      border-2 border-primary-light
                      text-white
                      py-2
                      px-10
                    "
                  >
                    Guardar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, sameAs, helpers } from 'vuelidate/lib/validators'

const hasSymbol = helpers.regex('hasSymbol', /^.*[-!$%^&*()_+|~=`{}[\]:";'<>?,./]+.*/)

export default {
  name: "PromotionsPage",
  data: function () {
    return {
      id: "",
      confirmError: true,
      showErrors: false,
      formData: {
        password: "",
        password_c: "",
      }
    };
  },
  validations() {
    let validations = {
      formData: {
        password: {
          required,
          hasSymbol,
          minLength: minLength(5)
        },
        password_c: {
          required,
          sameAsPassword: sameAs('password')
        }
      }
    };
    return validations;
  },
  computed: {},
  mounted() {
    this.$store.commit("updateBgApp", "");
  },
  destroyed() {},
  methods: {
    async onSubmit() {
      let self = this;
      let payload = {
        data: {
          token: this.$route.params.id,
          ...this.formData,
          domain: window.location.origin
        }
      }
      self.showErrors = false;
      self.$v.$touch();
      if (self.$v.$invalid) {
        self.showErrors = true;
      } else {        
        await self.$store.dispatch("ResetPassword", payload).then(() => {
          self.$notify({
            group: 'foo',
            type: "success",
            title: 'Solicitud procesada',
            text: '¡Tu cambio de contraseña se ha realizado! Ahora puedes iniciar sesión con tus credenciales nuevas.',
            duration: 10000
          })
          setTimeout(() => {
            self.$router.push({ name:'Inicio', params: {} })
          }, 1500);
        }).catch((e) => {
          self.$notify({
            group: 'foo',
            type: 'error',
            title: 'Error en cambio de contraseña',
            text: 'No fue posible actualizar la contraseña. Parece que hubo un error, intenta nuevamente mas tarde.' + e.response,
            duration: 10000
          })
        })
      }
    }
  },
};
</script>

<style >
.container {
  max-width: 800px !important;
}
</style>
